<template>
  <l-default>
    <em>We are suspending our texting service starting the 5th of July.</em>
    <CStickyBtnGroup position="top">
      <CNewConverstionButton></CNewConverstionButton>
    </CStickyBtnGroup>
    <CConversationsList></CConversationsList>
    <CAdBitMarketPulse/>
  </l-default>
</template>

<script>
import LDefault from '@/layouts/LDefault'
import CConversationsList from '@/components/anonymousTexting/CConversationsList'
import CNewConverstionButton from '@/components/anonymousTexting/CNewConversationButton'
import CStickyBtnGroup from '../../components/CStickyBtnGroup'
import CAdBitMarketPulse from '../../components/CAdBitMarketPulse'

export default {
  name: 'PageConversations',
  components: {
    CStickyBtnGroup,
    CNewConverstionButton,
    CConversationsList,
    LDefault,
    CAdBitMarketPulse
  }
}
</script>

<style scoped>
.aweb {
  margin-bottom: 1rem;
}
</style>
